/** Helvetica Neue Regular **/
@font-face {
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/HelveticaNeue/HelveticaNeueRegular.woff2") format("woff2"), url("../fonts/HelveticaNeue/HelveticaNeueRegular.woff") format("woff");
  font-display: swap;
}

/** Helvetica Neue Bold **/
@font-face {
  font-family: "Helvetica Neue";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/HelveticaNeue/HelveticaNeueBold.woff2") format("woff2"), url("../fonts/HelveticaNeue/HelveticaNeueBold.woff") format("woff");
  font-display: swap;
}

/** Helvetica Neue Ultra Bold **/
@font-face {
  font-family: "Helvetica Neue";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/HelveticaNeue/HelveticaNeueHeavy.woff2") format("woff2"), url("../fonts/HelveticaNeue/HelveticaNeueHeavy.woff") format("woff");
  font-display: swap;
}
